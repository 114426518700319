<template>
  <div class=" container-fluid container-fixed-lg">
    <Modal modal-class="modal-confirm slide-up" size="md" ref="modal_fee_rule_hotel" modal-header-title="お知らせ">
      <template v-slot:body>
        <div class="confirm-body text-left">
          <div>この期間の宿泊料金はシステムに登録されていません。</div>
          <div>宿泊料金をご登録ください。</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="width: 100%; padding: 10px 14px" class="row justify-content-end">
          <div>
            <button type="button"
                    :class="['btn btn-default no-margin inline btn-no']"
                    data-dismiss="modal">
              閉じる
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal modal-class="modal-confirm slide-up" size="md" ref="modal_fee_rule_taxi" modal-header-title="お知らせ">
      <template v-slot:body>
        <div class="confirm-body text-left">
          <div>この期間のタクシー料金はシステムに登録されていません。</div>
          <div>タクシー料金をご登録ください。</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="width: 100%; padding: 10px 14px" class="row justify-content-end">
          <div>
            <button type="button"
                    :class="['btn btn-default no-margin inline btn-no']"
                    data-dismiss="modal">
              閉じる
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal modal-class="modal-confirm slide-up" size="md" ref="kidchair_notice" modal-header-title="お知らせ">
      <template v-slot:body>
        <div class="confirm-body text-left">
          <div>チャイルドシートの数が足りません。</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="width: 100%; padding: 10px 14px" class="row justify-content-end">
          <div>
            <button type="button"
                    :class="['btn btn-default no-margin inline btn-no']"
                    data-dismiss="modal">
              閉じる
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal ref="modal_date_ranks" modal-class="modal-confirm slide-up" size="md" :on-hide="onHideDateRank"
           modal-header-title="日付設定">
      <template v-slot:container>
        <ValidationObserver ref="form_date_rank" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveDateRanks)">
            <div class="modal-body">
              <div class="app-table-container">
                <div class="dataTables_wrapper no-footer bg-white">
                  <div class="table-responsive sm-m-b-15">
                    <table :class="['table dataTable app-table no-footer']" style="border: 1px solid #a5a5a5">
                      <thead>
                      <th class="v-align-middle text-center">宿泊日付
                      </th>
                      <th class="v-align-middle text-center" style="width: 60px">平日
                      </th>
                      <th class="v-align-middle text-center" style="width: 60px">休前日
                      </th>
                      <th class="v-align-middle text-center" style="width: 60px">特日</th>
                      </thead>
                      <tbody>
                      <tr :class="entry.date_ranks[date].has_error ? 'has-error' : ''"
                          v-for="(rank, date) in entry.date_ranks">
                        <td class="app-text-bold">{{ date }}</td>
                        <td class="text-center">
                          <div class="radio radio-success">
                            <input :key="date + 'input_1'" type="radio" :id="date + 1" :name="date"
                                   @change="setDateRankVaild(date)"
                                   v-model="entry.date_ranks[date].date_type"
                                   value="1"/>
                            <label :key="date + 1" :for="date + 1"></label>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="radio radio-success">
                            <input :key="date + 'input_2'" type="radio" :id="date + 2" :name="date"
                                   v-model="entry.date_ranks[date].date_type"
                                   @change="setDateRankVaild(date)"
                                   value="2"/>
                            <label :key="date + 2" :for="date + 2"></label>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="radio radio-success">
                            <input :key="date + 'input_3'" type="radio" :id="date + 3" :name="date"
                                   v-model="entry.date_ranks[date].date_type"
                                   @change="setDateRankVaild(date)"
                                   value="3"/>
                            <label :key="date + 3" :for="date + 3"></label>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <div class="card card-transparent">
      <div class="card-header d-flex justify-content-between">
        <div class="page-title card-title">
          予約登録
        </div>
      </div>
      <div class="card-body">
        <HeaderNotice ref="hotel_fee_rule_notice"/>
        <HeaderNotice ref="taxi_fee_rule_notice"/>
        <HeaderNotice ref="kidchair_available_notice"/>
        <div data-pages="card" class="card card-default">
          <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
            <form novalidate autocomplete="off" @submit.prevent="() => {}">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <AppDatePicker :value="formatDate(entry.booking_date)"
                                   v-model="entry.booking_date"
                                   rules="required"
                                   name="booking_date"
                                   :options="{startDate: new Date()}"
                                   :label="$t('booking.booking_date')"/>
                  </div>
                </div>
                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppSelect :options="{containerCssClass: 'z-index-0', placeholder: $t('hotel.hotel_name')}"
                               name="hotel_id"
                               :label="$t('hotel.hotel_name')" v-model="entry.hotel_id"
                               @change="changeHotel"
                               :options-data="listHotel"
                               rules="required"/>
                    <ValidationObserver ref="start_point" v-slot="{ passes, valid, validated }">
                      <AppInput hidden name="start_point_force_reject" v-model="validate.start_point_force_reject"/>
                      <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.start_point')}"
                                 name="start_point"
                                 :label="$t('common.start_point')"
                                 rules="required|start_point_booking:@start_point_force_reject"
                                 :options-data="startPoints"
                                 @change="startPointChange"
                                 boundary-class="b-b-0"
                                 v-model="entry.start_point_id"/>
                    </ValidationObserver>
                  </div>
                  <div class="col-md-6 break-left p-l-0">
                    <div class="row">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                       <span class="app-text-bold text-complete">
                            宿泊日程
                        </span>
                      </div>
                      <div class="group-input-value">
                        <div class="row">
                          <div class="col-md-9">
                            <ValidationObserver ref="checkout_date" v-slot="{ passes, valid, validated }">
                              <AppDatePicker rules="required" v-model="entry.checkin_date"
                                             name="checkin_date"
                                             :on-change="checkinDateChange"
                                             :options="{startDate: new Date(), endDate: entry.checkout_date}"
                                             :label="$t('booking.checkin_date')"/>
                              <AppDatePicker rules="required|after_date:@checkin_date" v-model="entry.checkout_date"
                                             name="checkout_date"
                                             :on-change="checkoutDateChange"
                                             :options="{startDate: new Date()}"
                                             :label="$t('booking.checkout_date')"/>
                            </ValidationObserver>
                          </div>
                          <div class="col-md-3">
                            <div class="d-flex justify-content-center align-items-center day-count">
                          <span class="app-text-bold text-complete">
                            {{ entry.hotel_night_count }}泊{{ entry.hotel_day_count }}日
                        </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-9">
                            <div class="app-form-group row form-group b-b-0 m-b-0">
                              <label class="col-md-4 input-label control-label p-l-0">
                                {{ $t('booking.date_rank') }}<span class="text-danger"> ＊</span>
                              </label>
                              <div class="col-md-8 form-group m-b-0 p-l-0">
                                <div class="">
                                  <button type="button" @click="dateRankShow" class="btn btn-create">
                                    {{ $t('booking.date_rank_select') }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.hotel_room_type')}"
                               name="hotel_room_type"
                               :label="$t('common.hotel_room_type')" rules="required"
                               boundary-class="m-t-15"
                               :options-data="hotelRoomTypes"
                               v-model="entry.room_type_id"/>
                    <div class="row">
                      <div class="col-md-6">
                        <AppInput v-model="entry.room_num" type="number-length:1" :label="$t('booking.room num')"
                                  rules="required"/>
                      </div>
                      <div class="col-md-6">
                        <AppInput v-model="entry.room_type_people_num" type="number-length:1"
                                  label="１室の人数" rules="required"/>
                      </div>
                    </div>
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: '宿泊料金プラン'}"
                               name="hotel_fee_rule"
                               label="宿泊料金プラン" rules="required"
                               :options-data="hotelFeeRuleList"
                               @change="hotelFeeRuleChange"
                               v-model="hotelFeeRule.id"/>
                    <AppInput type="string-length:30" :label="$t('booking.c_name')" rules="required" v-model="entry.c_name"/>
                    <AppInput type="string-length:30" :label="$t('booking.c_name_kana')"
                              v-model="entry.c_name_kana"/>
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.c_gender')}"
                               name="c_gender"
                               :label="$t('booking.c_gender')" rules="required"
                               :options-data="$consts.BOOKING.GENDERS"
                               v-model="entry.c_gender"/>
                    <ValidationObserver ref="c_phone_mobile" v-slot="{ passes, valid, validated }">
                      <AppInput boundary-class="booking-input"
                                type="number-length:11"
                                v-model="entry.c_phone_mobile"
                                rules="phone_mobile"
                                :label="$t('booking.c_phone')">
                        <template v-slot:append>
                          （携帯）
                        </template>
                      </AppInput>
                    </ValidationObserver>
                    <ValidationObserver ref="c_phone_home" v-slot="{ passes, valid, validated }">
                      <AppInput boundary-class="booking-input"
                                type="number-length:10"
                                rules="phone_home"
                                v-model="entry.c_phone_home"
                                label=" ">
                        <template v-slot:append>
                          （自宅）
                        </template>
                      </AppInput>
                    </ValidationObserver>
                    <ValidationObserver ref="c_email" v-slot="{ passes, valid, validated }">
                      <AppInput name="c_email" type="email" :label="$t('common.mail address')"
                                rules="email"
                                v-model="entry.c_email"/>
                    </ValidationObserver>
                    <ValidationObserver ref="c_postal_code" v-slot="{ passes, valid, validated }">
                      <AppInput hidden name="postal_code_force_reject" v-model="validate.postal_code_force_reject"/>
                      <AppInput name="c_postal_code"
                                type="number-length:7"
                                :label="$t('booking.c_postal_code')"
                                rules="required|ja_postal_code:@postal_code_force_reject"
                                v-model="entry.c_postal_code"/>
                    </ValidationObserver>
                    <AppInput name="c_address" type="string-length:30" :label="$t('booking.c_address')"
                              rules="required"
                              v-model="entry.c_address"/>
                    <AppInput name="c_note" type="string" :label="$t('booking.c_note')"
                              boundary-class="b-b-0 m-b-0"
                              v-model="entry.c_note"/>
                  </div>
                  <div class="col-md-6 p-l-0 break-left">
                    <div class="row break-bottom">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                        <span class="app-text-bold text-complete">人数</span>
                      </div>
                      <div class="group-input-value">
                        <AppInput type="number-length:1" name="c_num_note_1" :label="$t('booking.c_num_1')"
                                  col-input="4"
                                  boundary-class="m-t-15"
                                  v-model="entry.c_num_note_1"/>
                        <AppInput type="number-length:1" name="c_num_note_2" :label="$t('booking.c_num_2')"
                                  col-input="4"
                                  v-model="entry.c_num_note_2"/>
                        <AppInput type="number-length:1" name="c_num_note_3" :label="$t('booking.c_num_3')"
                                  col-input="4"
                                  v-model="entry.c_num_note_3"/>
                        <AppInput type="number-length:1" name="c_num_note_4" :label="$t('booking.c_num_4')"
                                  col-input="4"
                                  v-model="entry.c_num_note_4"/>
                        <AppInput type="number-length:1" name="c_num_4" :label="$t('booking.c_num_5')" col-input="4"
                                  v-model="entry.c_num_4"/>
                        <AppInput type="number-length:1" name="c_num_5" :label="$t('booking.c_num_6')" col-input="4"
                                  v-model="entry.c_num_5"/>
                        <AppInput type="number_format" name="c_num_total" :label="$t('booking.c_num_total')"
                                  col-input="4"
                                  boundary-class="b-b-0 m-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_num_total"/>
                      </div>
                    </div>
                    <div class="row break-bottom">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                    <span class="app-text-bold text-complete">
                            食事数
                        </span>
                      </div>
                      <div class="group-input-value">
                        <AppInput type="number-length:2" name="c_meal_1" :label="$t('booking.c_meal_1')" col-input="4"
                                  boundary-class="m-t-15"
                                  v-model="entry.c_meal_1"/>
                        <AppInput type="number-length:2" name="c_meal_2" :label="$t('booking.c_meal_2')" col-input="4"
                                  boundary-class="b-b-0 m-b-0"
                                  v-model="entry.c_meal_2"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                    <span class="app-text-bold text-complete">
                            アレルギー等
                        </span>
                      </div>
                      <div class="group-input-value">
                        <AppInput type="string" name="meal_allergy_notes" :label="$t('booking.meal_allergy_notes')"
                                  boundary-class="b-b-0 m-t-15 m-b-0"
                                  v-model="entry.meal_allergy_notes"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row break-bottom">
                  <div class="col-md-6">
                    <div class="m-t-15">
                      <div class="text-complete app-text-bold"> ホテル宿泊料金（サービス料・消費税込、入湯税込）</div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_1"
                                  :label="$t('booking.c_price_1')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_1">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="int" name="c_price_1" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_num_1">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_1">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_2"
                                  :label="$t('booking.c_price_2')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_2">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="number-length:1" name="c_price_2" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  v-model="entry.c_num_2">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_2">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_3"
                                  :label="$t('booking.c_price_3')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_3">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="number-length:1" name="c_price_3" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  v-model="entry.c_num_3">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_3">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_4"
                                  :label="$t('booking.c_price_4')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_4">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="int" name="c_price_4" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entry.c_num_4">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_4">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_5"
                                  :label="$t('booking.c_price_5')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_5">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-r-0 p-l-0">
                        <AppInput type="int" name="c_price_5" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entry.c_num_5">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_5">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_driver"
                                  :label="$t('booking.c_price_driver')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_driver">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 break-left">
                    <div class="row b-b-input">
                      <div class="col-lg-4">
                        <AppInput v-model="entry.c_age" type="number-length:3" label="年齢"
                                  boundary-class="m-t-15 m-b-0 b-b-0"
                                  col-input="6" col-label="6"/>
                      </div>
                      <div class="col-lg-2"></div>
                      <div class="col-lg-6">
                        <AppRadio
                          :radio-inputs="[
                        {label: 'WEB', value: 1},
                       {label: 'TEL', value: 2}
                       ]" name="receive_type" label="経路"
                          boundary-class="m-t-20 m-b-0 b-b-0"
                          v-model="entry.receive_type"/>
                      </div>
                    </div>
                    <AppText :label="$t('booking.c_age_notes')" name="c_age_notes" v-model="entry.c_age_notes"
                             boundary-class="m-t-15"
                    />
                    <AppInput type="number_format" name="c_price_total" v-model="entryComputed.c_price_total"
                              disabled="true"
                              :label="$t('booking.c_price_total')"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('taxi.taxi company name')}"
                               name="taxi_company_id"
                               :label="$t('taxi.taxi company name')"
                               boundary-class="m-t-15"
                               @change="taxiCompanyChange"
                               v-model="entry.taxi_company_id"
                               :options-data="listTaxiCompanies"
                               rules="required"/>
                  </div>
                  <div class="col-md-6  break-left"></div>
                </div>
                <ValidationObserver ref="checkout_taxi_date" v-slot="{ passes, valid, validated }">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row app-form-group p-b-0">
                        <div class="col-md-9 p-l-0">
                          <AppDatePicker v-model="entry.checkin_taxi_date" name="checkin_taxi_date"
                                         boundary-class="b-b-0 p-b-0"
                                         col-input="6"
                                         rules="required"
                                         col-label="6"
                                         :options="{startDate: new Date()}"
                                         :label="$t('booking.checkin_taxi_datetime')"/>
                        </div>
                        <div class="col-md-3">
                          <VueTimepicker format="HH:mm"
                                         hour-label="時"
                                         minute-label="分"
                                         placeholder="時:分"
                                         v-model="entry.checkin_taxi_time"
                                         :minute-interval="30">
                          </VueTimepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 break-left">
                      <div class="row app-form-group p-b-0">
                        <div class="col-md-9 p-l-0">
                          <AppDatePicker v-model="entry.checkout_taxi_date" name="checkout_taxi_date"
                                         boundary-class="b-b-0 p-b-0"
                                         col-input="6"
                                         col-label="6"
                                         rules="required|after_date:@checkin_taxi_date"
                                         :options="{startDate: new Date()}"
                                         :label="$t('booking.checkout_taxi_datetime')"/>
                        </div>
                        <div class="col-md-3">
                          <VueTimepicker format="HH:mm"
                                         hour-label="時"
                                         minute-label="分"
                                         placeholder="時:分"
                                         v-model="entry.checkout_taxi_time"
                                         :minute-interval="30">
                          </VueTimepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="pickup_point" rules="required" v-slot="{ errors, invalid, validated }">
                      <AppRadio
                        name="pickup_point"
                        rules="required"
                        :radio-inputs="[
                          {label: 'ご自宅', value: 1},
                         {label: 'ご自宅以外', value: 0}
                         ]"
                        :label="$t('booking.pickup_point')"
                        boundary-class="m-t-15"
                        :error_msg="invalid && validated ? errors[0] : null"
                        v-model="entry.is_pickup_point_home"/>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 break-left"></div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <AppInput :label="$t('booking.pickup_point_notes')"
                              type="string-length:30"
                              rules="required"
                              v-model="entry.pickup_point_notes"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <ValidationObserver ref="taxi_type" v-slot="{ passes, valid, validated }">
                      <AppInput hidden name="taxi_type_force_reject" v-model="validate.taxi_type_force_reject"/>
                      <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.taxi_type')}"
                                 name="taxi_type"
                                 @change="taxiTypeChange"
                                 :label="$t('common.taxi_type')"
                                 rules="required|taxi_type_booking:@taxi_type_force_reject"
                                 :options-data="taxiTypes"
                                 v-model="entry.taxi_type_id"/>
                    </ValidationObserver>
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: 'タクシー料金プラン'}"
                               name="taxi_fee_rule"
                               label="タクシー料金プラン" rules="required"
                               :options-data="taxiFeeRuleList"
                               @change="taxiFeeRuleChange"
                               v-model="taxiFeeRule.id"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <AppRadio
                      :radio-inputs="[
                  {label: '要', value: 1},
                 {label: '不要', value: 0}
                 ]" name="use_wheelchair" :label="$t('booking.use_wheelchair')"
                      v-model="entry.use_wheelchair"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppRadio
                      :radio-inputs="[
                      {label: '車椅子のままご乗車（福祉車）', value: 1},
                     {label: '折り畳み積載', value: 0}
                     ]" name="wheelchair_type" label="車椅子ご乗車方法"
                      ref="wheelchair_type"
                      :disabled="entry.use_wheelchair != 1"
                      v-model="entry.wheelchair_type"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <AppRadio
                      :radio-inputs="[
                  {label: '要', value: 1},
                 {label: '不要', value: 0}
                 ]" name="use_kidchair" :label="$t('booking.use_kidchair')"
                      v-model="entry.use_kidchair"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppInput :label="$t('booking.kidchair_num')"
                              type="int"
                              name="kidchair_num"
                              :rules="entry.use_kidchair != 1 ? '' : 'required|min_value:1'"
                              :blur="checkAvailableKidchair"
                              :disabled="entry.use_kidchair != 1"
                              v-model="entry.kidchair_num"/>
                  </div>
                </div>
                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppText :label="$t('booking.taxi_notes')" v-model="entry.taxi_notes" boundary-class="b-b-0"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppInput type="number-length:3" col-label="6" col-input="6"
                              :disabled="entry.use_kidchair != 1"
                              boundary-class="booking-input"
                              label="チャイルドシートをご利用になるお子様の年齢"
                              v-model="entry.child_age_note">
                      <template v-slot:append>（ 歳 ）</template>
                    </AppInput>
                    <AppInput type="number-length:3" col-label="6" col-input="6"
                              :disabled="entry.use_kidchair != 1"
                              boundary-class="booking-input b-b-0 p-b-0" label="チャイルドシートをご利用になるお子様の身長"
                              v-model="entry.child_hight_note">
                      <template v-slot:append>（cm）</template>
                    </AppInput>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <AppSelect :label="$t('booking.payment_method')"
                               v-model="entry.payment_method"
                               boundary-class="m-t-15"
                               rules="required"
                               :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.payment_method')}"
                               :options-data="$consts.BOOKING.PAYMENTS"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppSelect :label="$t('booking.driver_stay_info')"
                               boundary-class="m-t-15"
                               :options-data="listHotel"
                               :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.driver_stay_info')}"
                               v-model="entry.driver_hotel_id"/>
                  </div>
                </div>

                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppInput disabled="true" label="タクシー料金"
                              boundary-class="booking-input"
                              type="number_format"
                              v-model="entryComputed.total_price_taxi" name="total_price_taxi">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput disabled="true" :label="$t('booking.total_price_hotel')"
                              boundary-class="booking-input"
                              type="number_format"
                              v-model="entryComputed.total_price_hotel" name="total_price_hotel">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput disabled="true" :label="$t('booking.total_price_taxi')"
                              boundary-class="booking-input"
                              type="number_format"
                              v-model="entryComputed.total_price_taxi_with_options" name="total_price_taxi">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput :label="$t('booking.total_price_tourist_options')"
                              boundary-class="booking-input"
                              type="bigint"
                              v-model="entry.price_tourist_options" name="total_price_tourist_options">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput :label="$t('booking.total_price_discount')"
                              boundary-class="booking-input"
                              type="bigint"
                              v-model="entry.total_price_discount" name="total_price_discount">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput disabled="true" :label="$t('booking.total_price_final')"
                              boundary-class="booking-input b-b-0 m-b-0"
                              type="number_format"
                              v-model="entryComputed.total_price_final" name="total_price_final">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppRadio
                      :radio-inputs="[
                  {label: '利用する', value: 1},
                 {label: '利用しない', value: 0}
                 ]" name="use_tourist_option" :label="$t('booking.use_tourist_option')"
                      boundary-class="b-b-0 p-b-0 m-b-0"
                      v-model="entry.use_tourist_option"/>
                    <div class="app-text-bold text-complete m-b-15">
                      チェックボックスにチェックすると、タクシー設定にて設定されたコース情報が 表示されます。
                    </div>
                    <div class="dataTables_wrapper no-footer bg-white">
                      <div class="table-responsive sm-m-b-15">
                        <table v-if="entry.use_tourist_option == 1"
                               class="table dataTable app-table no-footer table-tourist-options">
                          <thead>
                          <tr>
                            <th class="text-center cell-fit-content">
                            </th>
                            <th style="min-width: 100px">コース名</th>
                            <th style="min-width: 100px">時間</th>
                            <th style="min-width: 100px">料金</th>
                            <th style="min-width: 100px">車種</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-if="!touristOptions.length">
                            <td colspan="7">観光オプション料金はマスター登録されていません。</td>
                          </tr>
                          <template v-else>
                            <tr v-for="touristOption in touristOptions">
                              <td style="width: 10px !important;" class="v-align-middle">
                                <input class="booking-checkbox" :id="'tourist_option_' + touristOption.id"
                                       :value="touristOption.id"
                                       v-model="entry.tourist_options"
                                       type="checkbox">
                              </td>
                              <td class="v-align-middle">{{ touristOption.name }}</td>
                              <td class="v-align-middle">{{ touristOption.time }}</td>
                              <td class="v-align-middle">{{ formatNumber(touristOption.price) }}(円)</td>
                              <td class="v-align-middle">{{ touristOption.taxi_type.name }}</td>
                            </tr>
                            <tr>
                              <td>合計</td>
                              <td colspan="6" style="text-align: left !important;">{{
                                  formatNumber(entryComputed.total_price_tourist_options)
                                }}(円)
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppSelectMulti :options="{containerCssClass: 'z-index-0'}" name="c_langs"
                                    :label="$t('booking.c_langs')"
                                    boundary-class="m-t-15"
                                    :options-data="$consts.BOOKING.LANGS"
                                    v-model="entry.c_langs"/>
                  </div>

                  <div class="col-md-6 break-left">
                    <AppSelect :label="$t('booking.driver_taxi_type_id')"
                               v-model="entry.driver_taxi_type_id"
                               :options-data="driverTaxiTypes"
                               boundary-class="m-t-25"
                               :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.driver_taxi_type_id')}"/>
                    <AppInput :label="$t('booking.driver_offer_note')"
                              boundary-class="b-b-0 m-b-0"
                              type="string" v-model="entry.driver_offer_note"/>
                  </div>
                </div>
                <div class="row break-bottom">
                  <div class="col-md-6">
                    <div class="app-text-bold color-active">
                      ≪{{ entry.taxi_company_name }}様　 確認≫
                    </div>
                    <div class="app-text-bold color-active">
                      ＊＊＊こちらはタクシー会社のみ登録します。
                    </div>
                    <AppInput name="driver_name" :label="$t('booking.driver_name')" v-model="entry.driver_name"
                              type="string"
                              boundary-class="m-t-15"/>
                    <ValidationObserver ref="driver_phone" v-slot="{ passes, valid, validated }">
                      <AppInput type="number-length:11" rules="phone" name="driver_phone"
                                :label="$t('booking.driver_phone')"
                                v-model="entry.driver_phone"/>
                    </ValidationObserver>
                    <AppInput name="driver_license_num" :label="$t('booking.driver_license_num')"
                              v-model="entry.driver_license_num" type="string"/>
                    <AppSelectMulti :options="{containerCssClass: 'z-index-0'}" name="driver_langs"
                                    :label="$t('booking.driver_langs')" :options-data="$consts.BOOKING.LANGS"
                                    v-model="entry.driver_langs"/>
                  </div>

                  <div class="col-md-6 break-left">
                    <AppSelect :label="$t('booking.taxi_branch_name')"
                               v-model="entry.taxi_branch_id"
                               :options-data="taxiBranchList"
                               boundary-class="m-t-15"
                               :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.taxi_branch_name')}"/>
                    <AppText label="備考" v-model="entry.driver_note"/>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row m-b-50 justify-content-end">
                  <button @click="redirectBack" type="button"
                          :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                          data-dismiss="modal">
                    {{ $t('common.cancel') }}
                  </button>
                  <button @click="saveDraft" type="button"
                          :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                    一時保存
                  </button>
                  <button @click="saveBooking" type="button"
                          :class="['btn btn-cons inline btn-modal-submit', 'btn-create']">
                    保存＆送信
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      validate: {
        postal_code_force_reject: false,
        taxi_type_force_reject: false,
        start_point_force_reject: false,
      },
      listHotel: [],
      startPoints: [],
      hotelRoomTypes: [],
      listTaxiCompanies: [],
      driverTaxiTypes: [],
      taxiTypes: [],
      taxiBranchList: [],
      feeChildRule: {},
      hotelFeeRuleLoaded: false,
      hotelFeeRule: {},
      kidchair_validated: true,
      taxiFeeRuleLoaded: false,
      taxiFeeRule: {},
      taxiFeeRuleList: [],
      hotelFeeRuleList: [],
      touristOptions: [],
      entry: {
        booking_date: moment().format('YYYY/MM/DD'),
        date_ranks_default: {},
        date_ranks: {},
        driver_langs: [],
        tourist_options: [],
        checkin_taxi_time: '',
        checkout_taxi_time: '',
      },
    }
  },
  computed: {
    computedData: function () {
      return _.cloneDeep(this.entry)
    },
    entryComputed() {
      var computed = {};
      const {
        c_num_note_1, c_num_note_2, c_num_note_3, c_num_note_4,
        c_num_2, c_num_3, c_num_4, c_num_5, hotel_night_count,
        c_postal_code
      } = this.entry
      computed.c_postal_code = c_postal_code ? c_postal_code.toString().replace('-', '') : null;
      var c_num_1 = null;
      if (c_num_note_1 || c_num_note_2 || c_num_note_3) {
        c_num_1 = parseInt(c_num_note_1 ? c_num_note_1 : 0) +
          parseInt(c_num_note_2 ? c_num_note_2 : 0) +
          parseInt(c_num_note_3 ? c_num_note_3 : 0);
      }
      computed.c_num_1 = c_num_1;
      computed.c_num_total = parseInt(c_num_note_1 ? c_num_note_1 : 0) +
        parseInt(c_num_note_2 ? c_num_note_2 : 0) +
        parseInt(c_num_note_3 ? c_num_note_3 : 0) +
        parseInt(c_num_note_4 ? c_num_note_4 : 0) +
        parseInt(c_num_4 ? c_num_4 : 0) +
        parseInt(c_num_5 ? c_num_5 : 0);
      computed.c_night_1 = c_num_1 ? hotel_night_count : null;
      computed.c_night_2 = c_num_2 ? hotel_night_count : null;
      computed.c_night_3 = c_num_3 ? hotel_night_count : null;
      computed.c_night_4 = c_num_4 ? hotel_night_count : null;
      computed.c_night_5 = c_num_5 ? hotel_night_count : null;

      var c_price_1 = 0;
      var c_price_2 = 0;
      var c_price_3 = 0;
      var c_price_4 = 0;
      var c_price_5 = 0;
      var c_price_driver = 0;
      if (this.hotelFeeRuleLoaded && this.feeChildRule && this.hotelFeeRule) {
        var validateDateRank = true;
        Object.keys(this.entry.date_ranks).map((date) => {
          if (!this.entry.date_ranks[date].date_type) {
            validateDateRank = false;
          }
        })
        if (validateDateRank) {
          _.forEach(this.entry.date_ranks, date_rank => {
            let hotelFeeRule = this.getHotelUnitPrice(parseInt(date_rank.date_type));
            c_price_1 += hotelFeeRule;
            c_price_2 += Math.floor(hotelFeeRule * parseInt(this.feeChildRule.rate_1) / 100);
            c_price_3 += Math.floor(hotelFeeRule * parseInt(this.feeChildRule.rate_2) / 100);
            c_price_4 += Math.floor(hotelFeeRule * parseInt(this.feeChildRule.rate_3) / 100);
            c_price_5 += Math.floor(hotelFeeRule * parseInt(this.feeChildRule.rate_4) / 100);
            c_price_driver += this.getHotelUnitPrice(parseInt(date_rank.date_type), date_rank.date, true)
          });
        }
      }
      computed.c_price_1 = c_num_1 ? c_price_1 : null;
      computed.c_price_2 = c_num_2 ? c_price_2 : null;
      computed.c_price_3 = c_num_3 ? c_price_3 : null;
      computed.c_price_4 = c_num_4 ? c_price_4 : null;
      computed.c_price_5 = c_num_5 ? c_price_5 : null;
      computed.c_price_driver = c_price_driver;
      computed.c_price_total = c_price_1 * parseInt(c_num_1 ? c_num_1 : 0) +
        c_price_2 * parseInt(c_num_2 ? c_num_2 : 0) +
        c_price_3 * parseInt(c_num_3 ? c_num_3 : 0) +
        c_price_4 * parseInt(c_num_4 ? c_num_4 : 0) +
        c_price_5 * parseInt(c_num_5 ? c_num_5 : 0) +
        c_price_driver;
      computed.total_price_hotel = computed.c_price_total

      var total_price_taxi = 0;
      if (this.taxiFeeRuleLoaded && this.taxiFeeRule) {
        total_price_taxi = this.taxiFeeRule.price;
      }
      computed.total_price_taxi = total_price_taxi;

      var touristOptionTotal = 0;
      if (this.entry.use_tourist_option && this.touristOptions) {
        _.forEach(this.touristOptions, touristOption => {
          if (this.entry.tourist_options.includes(parseInt(touristOption.id))) {
            touristOptionTotal += touristOption.price;
          }
        });
      }
      computed.total_price_tourist_options = touristOptionTotal;
      computed.total_price_taxi_with_options = parseInt(computed.total_price_taxi) + parseInt(computed.total_price_tourist_options);
      var totalPriceFinal = parseInt(computed.total_price_hotel ? computed.total_price_hotel : 0) +
        parseInt(computed.total_price_taxi_with_options ? computed.total_price_taxi_with_options : 0) +
        parseInt(this.entry.price_tourist_options ? this.entry.price_tourist_options : 0) -
        parseInt(this.entry.total_price_discount ? this.entry.total_price_discount : 0);
      if (totalPriceFinal > 0) {
        computed.total_price_final = totalPriceFinal
      } else {
        computed.total_price_final = 0
      }
      return computed;
    },
  },
  watch: {
    computedData: {
      handler: function (entry, oldEntry) {
        if (_.isEqual(entry, oldEntry)) {
          return true;
        }
        const {checkin_date, checkout_date} = entry
        if (this.isGroupAttrUpdated(entry, oldEntry, ['checkin_date', 'checkout_date'])) {
          let hotelDayCount = moment(checkout_date).diff(checkin_date, 'days') + 1;
          this.entry.hotel_day_count = hotelDayCount > 0 ? hotelDayCount : 0
          this.entry.hotel_night_count = hotelDayCount > 0 ? hotelDayCount - 1 : 0
          this.resetEntryDateRanks();
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['c_num_note_4'])) {
          this.entry.c_num_2 = entry.c_num_note_4
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['c_postal_code'])) {
          if (entry.c_postal_code.length === 7 && oldEntry.c_postal_code.length <= 7) {
            this.applyPostalCode();
          }
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['checkin_date', 'checkout_date', 'hotel_id', 'room_type_id', 'room_type_people_num'])) {
          this.getHotelFeeRule();
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['taxi_company_id', 'start_point_id', 'taxi_type_id', 'hotel_id', 'checkin_date', 'checkout_date'])) {
          this.findTaxiFeeRule();
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['use_tourist_option'])) {
          this.entry.tourist_options = []
        }
        if (entry.taxi_company_id && entry.taxi_company_id != oldEntry.taxi_company_id ||
          entry.checkin_date && entry.checkout_date && (entry.checkin_date != oldEntry.checkin_date || entry.checkout_date != oldEntry.checkout_date)) {
          this.getTaxiTypes(500, false, () => {
            this.validateTaxiType();
            this.scrollToValidateError();
          })
        }
      },
      deep: true
    },
    'entry.use_kidchair': {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val == 0) {
            this.kidchair_validated = true;
            this.entry.kidchair_num = null;
            this.entry.child_age_note = null;
            this.entry.child_hight_note = null;
          }
          if (val == 1 && this.entry.kidchair_num > 0) {
            this.checkAvailableKidchair();
          }
        }
      },
      deep: true
    },
    'entry.use_wheelchair': {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val == 0) {
            this.entry.wheelchair_type = undefined;
            this.entry = _.clone(this.entry);
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$request.get(this.$consts.API.CS.BOOKING_RELATE_DATA, null, {noLoadingEffect: true}).then(res => {
      if (!res.hasErrors()) {
        const {hotels, taxis, start_points, fee_child_rule, date_ranks} = res.data;
        this.listHotel = hotels;
        this.startPoints = start_points;
        this.feeChildRule = fee_child_rule;
        this.entry.date_ranks_default = date_ranks
      }
    })
  },
  methods: {
    checkinDateChange() {
      if (this.entry.checkin_date && !this.entry.checkout_date) {
        this.entry.checkout_date = moment(this.entry.checkin_date).add(1, 'days').format('YYYY/MM/DD');
      }
      if (this.entry.checkin_date) {
        this.entry.checkin_taxi_date = this.entry.checkin_date;
      }
      this.entry = _.cloneDeep(this.entry);
    },
    checkoutDateChange() {
      if (this.entry.checkout_date) {
        this.entry.checkout_taxi_date = this.entry.checkout_date;
      }
      this.entry = _.cloneDeep(this.entry);
    },
    applyPostalCode() {
      const {c_postal_code} = this.entry
      if (c_postal_code && c_postal_code.length === 7) {
        this.$request.get(this.$consts.API.CS.POSTAL_CODE_FIND(c_postal_code.replace('-', '')), null, {noLoadingEffect: true}).then(res => {
          if (!res.hasErrors()) {
            const {province, city, area} = res.data
            this.entry.c_address = `${province}${city}${area ? ('' + area) : ''}`
            this.entry = _.cloneDeep(this.entry)
            this.validate.postal_code_force_reject = false;
          } else {
            this.validate.postal_code_force_reject = true;
            setTimeout(() => this.$refs.c_postal_code.validate(), 100);
          }
        })
      }
    },
    taxiCompanyChange() {
      _.forEach(this.listTaxiCompanies, taxi => {
        if (taxi.id == this.entry.taxi_company_id) {
          this.entry.taxi_company_name = taxi.name;
          this.taxiBranchList = taxi.taxi_branches
        }
      })
    },
    startPointChange() {
      if (!this.entry.start_point_id) {
        return false;
      }
      _.forEach(this.startPoints, startPoint => {
        if (startPoint.id == this.entry.start_point_id) {
          this.listTaxiCompanies = _.cloneDeep(startPoint.taxi_companies);
          if (!startPoint.taxi_companies || startPoint.taxi_companies.length === 0) {
            this.validate.start_point_force_reject = true;
            setTimeout(() => this.$refs.start_point.validate(), 100)
            return false;
          } else {
            this.validate.start_point_force_reject = false;
            this.$refs.start_point.validate()
            if (startPoint.taxi_companies.length === 1) {
              this.entry.taxi_company_id = startPoint.taxi_companies[0].id;
            }
            return true;
          }
        }
      })
    },
    changeHotel() {
      if (!this.entry.hotel_id) {
        return;
      }
      this.$request.get(this.$consts.API.CS.HOTEL_ROOM_TYPE_SELECT(this.entry.hotel_id), null, {noLoadingEffect: true}).then(res => {
        if (!res.hasErrors()) {
          this.hotelRoomTypes = res.data.data;
        }
      })
    },
    taxiTypeChange() {
      if (!this.entry.taxi_type_id) {
        return false;
      }
      this.validateTaxiType();
      this.$request.get(this.$consts.API.CS.TOURIST_OPTION_LIST_SELECT, {
        'filters[taxi_company_id][equal]': this.entry.taxi_company_id,
        'filters[taxi_type_id][equal]': this.entry.taxi_type_id,
      }, {noLoadingEffect: true}).then(res => {
        if (!res.hasErrors()) {
          this.touristOptions = res.data.data
        }
      })
    },
    validateTaxiType() {
      _.forEach(this.taxiTypes, taxiType => {
        if (taxiType.id == this.entry.taxi_type_id) {
          if (taxiType.can_not_booking) {
            this.validate.taxi_type_force_reject = true;
            setTimeout(() => this.$refs.taxi_type.validate(), 100);
          } else {
            this.validate.taxi_type_force_reject = false;
            this.$refs.taxi_type.validate();
          }
        }
      })
    },
    getHotelFeeRule() {
      const {hotel_id, room_type_id, checkin_date, checkout_date, room_type_people_num} = this.entry
      if (hotel_id && room_type_id && checkin_date && checkout_date && room_type_people_num && (moment(checkout_date).diff(moment(checkin_date)) > 0)) {
        if (window.hotelFeeRuleLoad) {
          clearTimeout(window.hotelFeeRuleLoad);
        }
        window.hotelFeeRuleLoad = setTimeout(() => {
          this.$request.get(this.$consts.API.CS.HOTEL_FEE_RULE_FIND, {
            hotel_id, room_type_id, checkin_date, checkout_date, room_type_people_num: room_type_people_num
          }, {noLoadingEffect: true}).then(res => {
            this.hotelFeeRule = {}
            this.hotelFeeRuleList = []
            this.hotelFeeRuleLoaded = false;
            if (!res.hasErrors()) {
              this.hotelFeeRuleList = res.data.map(feeRule => {
                feeRule.name += `（${this.formatDate(feeRule.start_date)} - ${this.formatDate(feeRule.end_date)}）`
                return feeRule;
              })
              if (this.hotelFeeRuleList.length < 1) {
                this.$refs.modal_fee_rule_hotel.show();
              }
              if (res.data.length == 1) {
                this.hotelFeeRuleLoaded = true;
                this.hotelFeeRule = res.data[0];
              }
            } else {
              this.$refs.modal_fee_rule_hotel.show();
            }
          })
        }, 500)
      }
    },
    checkAvailableKidchair(timeout = 500, noLoading = true) {
      const {taxi_company_id, kidchair_num, checkin_date, checkout_date} = this.entry
      if (taxi_company_id && kidchair_num && checkin_date && checkout_date && (moment(checkout_date).diff(moment(checkin_date)) > 0)) {
        if (window.checkAvailableKidchair) {
          clearTimeout(window.checkAvailableKidchair);
        }
        window.checkAvailableKidchair = setTimeout(() => {
          this.$request.get(this.$consts.API.CS.BOOKING_CHECK_KIDCHAIR, {
            taxi_company_id, kidchair_num, checkin_date, checkout_date
          }, {noLoadingEffect: noLoading}).then(res => {
            if (!res.hasErrors() && res.data?.can_booking) {
              this.kidchair_validated = true;
            } else {
              this.$refs.kidchair_notice.show();
              this.kidchair_validated = false;
            }
          })
        }, timeout);
      }
    },
    findTaxiFeeRule() {
      const {taxi_company_id, taxi_type_id, start_point_id, hotel_id, checkin_date, checkout_date} = this.entry
      if (taxi_company_id && taxi_type_id && start_point_id && hotel_id && checkin_date && checkout_date && (moment(checkout_date).diff(moment(checkin_date)) > 0)) {
        if (window.taxiFeeRuleLoad) {
          clearTimeout(window.taxiFeeRuleLoad);
        }
        window.taxiFeeRuleLoad = setTimeout(() => {
          this.$request.get(this.$consts.API.CS.TAXI_FEE_RULE_FIND, {
            taxi_company_id, taxi_type_id, start_point_id, hotel_id, checkin_date, checkout_date
          }, {noLoadingEffect: true}).then(res => {
            this.taxiFeeRule = {}
            this.taxiFeeRuleList = []
            this.taxiFeeRuleLoaded = false;
            if (!res.hasErrors()) {
              this.taxiFeeRuleList = res.data.map(feeRule => {
                feeRule.name += `（${this.formatDate(feeRule.start_date)} - ${this.formatDate(feeRule.end_date)}）`
                return feeRule;
              })
              if (this.taxiFeeRuleList.length < 1) {
                this.$refs.modal_fee_rule_taxi.show();
              }
              if (res.data.length == 1) {
                this.taxiFeeRuleLoaded = true;
                this.taxiFeeRule = res.data[0];
              }
            } else {
              this.$refs.modal_fee_rule_taxi.show();
            }
          })
        }, 500);
      }
    },
    getTaxiTypes(timeout = 500, noLoading = true, callback = null) {
      const {taxi_company_id, checkin_date, checkout_date} = this.entry
      if (taxi_company_id && checkin_date && checkout_date && (moment(checkout_date).diff(moment(checkin_date)) > 0)) {
        if (window.getTaxiTypesLoad) {
          clearTimeout(window.getTaxiTypesLoad);
        }
        window.getTaxiTypesLoad = setTimeout(() => {
          this.$request.get(this.$consts.API.CS.BOOKING_TAXI_TYPE, {
            taxi_company_id, checkin_date, checkout_date
          }, {noLoadingEffect: noLoading}).then(res => {
            if (!res.hasErrors()) {
              this.taxiTypes = res.data.data
              this.driverTaxiTypes = _.cloneDeep(res.data.data)
              this.taxiTypes = _.clone(this.taxiTypes.map(taxiType => {
                if (taxiType.can_not_booking) {
                  taxiType.name += '（在庫なし）'
                } else {
                  taxiType.name += '（在庫あり）'
                }
                return taxiType;
              }))
            } else {
              this.taxiTypes = []
            }
            if (callback) {
              callback()
            }
          })
        }, timeout);
      }
    },
    dateRankShow() {
      if (Object.keys(this.entry.date_ranks).length) {
        this.$refs.modal_date_ranks.show()
      }
    },
    onHideDateRank() {
      this.resetEntryDateRanks();
    },
    resetEntryDateRanks() {
      var dateRanks = {};
      for (let i = 0; i < this.entry.hotel_night_count; i++) {
        let newDate = moment(new Date(this.entry.checkin_date)).add(i, 'days').format('YYYY/MM/DD')
        if (this.entry.date_ranks_default[newDate]) {
          dateRanks[newDate] = {
            date_type: this.entry.date_ranks_default[newDate],
            date: newDate,
          }
        } else {
          dateRanks[newDate] = {
            date_type: null,
            date: newDate,
          }
        }
      }
      this.entry.date_ranks = dateRanks
    },
    saveDateRanks() {
      if (!this.validateDateRank()) {
        return false;
      }
      this.$request.patch(this.$consts.API.CS.DATE_RANK_UPDATE, {
        date_ranks: this.entry.date_ranks
      }).then(res => {
        if (!res.hasErrors()) {
          this.$refs.modal_date_ranks.hide();
          this.entry.date_ranks_default = res.data
          this.entry = _.cloneDeep(this.entry)
        } else {
          this.$appNotice.error()
        }
      })
    },
    validateDateRank() {
      var validateDateRank = true;
      Object.keys(this.entry.date_ranks).map((date) => {
        if (!this.entry.date_ranks[date].date_type) {
          this.entry.date_ranks[date].has_error = true;
          validateDateRank = false;
        }
      })
      if (!validateDateRank) {
        this.entry.date_ranks = _.cloneDeep(this.entry.date_ranks)
        return false;
      }
      return true;
    },
    setDateRankVaild(date) {
      this.entry.date_ranks[date].has_error = false;
      this.entry.date_ranks = _.cloneDeep(this.entry.date_ranks)
    },
    getHotelUnitPrice(dateType, isDriver = false) {
      var feeRule = this.hotelFeeRule?.fee_rule_detail;
      if (!feeRule) {
        feeRule = {
          fee_normal: 0,
          fee_holiday: 0,
          fee_special: 0,
          fee_driver_normal: 0,
          fee_driver_holiday: 0,
          fee_driver_special: 0,
        }
      }
      if (isDriver) {
        switch (dateType) {
          case 1: {
            return parseInt(feeRule.fee_driver_normal);
          }
          case 2: {
            return parseInt(feeRule.fee_driver_holiday);
          }
          case 3: {
            return parseInt(feeRule.fee_driver_special);
          }
        }
      }
      switch (dateType) {
        case 1: {
          return parseInt(feeRule.fee_normal);
        }
        case 2: {
          return parseInt(feeRule.fee_holiday);
        }
        case 3: {
          return parseInt(feeRule.fee_special);
        }
      }
      return 0;
    },
    isGroupAttrUpdated(newObj, oldObj, attrs) {
      var isGroupHasValue = true;
      var isHasChange = false;
      _.forEach(attrs, attr => {
        if (!newObj[attr]) {
          isGroupHasValue = false;
          return false;
        }
      })
      _.forEach(attrs, attr => {
        if (oldObj[attr] !== newObj[attr]) {
          isHasChange = true;
          return true;
        }
      })
      return isGroupHasValue && isHasChange;
    },
    redirectHotelFeeRule() {
      window.open(this.$router.resolve({
        name: this.$consts.ROUTES.CS.HOTEL_FEE_RULE_LIST,
        params: {id: this.entry.hotel_id}
      }).href, '_blank')
    },
    taxiFeeRuleChange() {
      _.forEach(this.taxiFeeRuleList, feeRule => {
        if (feeRule.id == this.taxiFeeRule.id) {
          this.taxiFeeRule = _.cloneDeep(feeRule);
          this.taxiFeeRuleLoaded = true;
          return;
        }
      })
    },
    redirectTaxiFeeRule() {
      window.open(this.$router.resolve({
        name: this.$consts.ROUTES.CS.TAXI_FEE_RULE,
        params: {id: this.entry.taxi_company_id}
      }).href, '_blank')
    },
    hotelFeeRuleChange() {
      _.forEach(this.hotelFeeRuleList, feeRule => {
        if (feeRule.id == this.hotelFeeRule.id) {
          this.hotelFeeRule = _.cloneDeep(feeRule);
          this.hotelFeeRuleLoaded = true;
          return;
        }
      })
    },
    async saveDraft() {
      var result = true;
      this.$refs.form_create_entry.reset()
      if (this.entry.c_email && this.entry.c_email.length) {
        result = result && await this.$refs.c_email.validate();
      }
      if (this.entry.c_postal_code && this.entry.c_postal_code.length) {
        result = result && await this.$refs.c_postal_code.validate();
      }
      if (this.entry.checkin_date && this.entry.checkin_date.length && this.entry.checkout_date && this.entry.checkout_date.length) {
        result = result && await this.$refs.checkout_date.validate();
      }
      if (this.entry.checkin_taxi_date && this.entry.checkin_taxi_date.length && this.entry.checkout_taxi_date && this.entry.checkout_taxi_date.length) {
        result = result && await this.$refs.checkout_taxi_date.validate();
      }
      result = result && await this.$refs.c_phone_mobile.validate();
      result = result && await this.$refs.c_phone_home.validate();
      result = result && await this.$refs.driver_phone.validate();
      if (!result) {
        this.scrollToValidateError();
        return false;
      }
      var toIds = this.touristOptions.map(to => to.id)
      this.entry.tourist_options = this.entry.tourist_options.filter(id => toIds.includes(id))
      var entryComputed = _.cloneDeep(this.entryComputed);
      var commaPriceFields = ['c_num_total', 'c_price_1', 'c_price_2', 'c_price_3', 'c_price_4', 'c_price_5',
        'c_price_driver', 'c_price_total', 'total_price_taxi', 'total_price_hotel',
        'total_price_taxi_with_options', 'total_price_final'];
      _.forEach(commaPriceFields, field => {
        if (entryComputed[field] && (typeof entryComputed[field] === 'string')) {
          entryComputed[field] = entryComputed[field].replace(/,/g, "");
        }
      })
      this.$request.post(this.$consts.API.CS.BOOKING_SAVE_DRAFT, {
        ...this.entry,
        ...entryComputed,
        hotel_fee_rule_id: this.hotelFeeRule.id,
        taxi_fee_rule_id: this.taxiFeeRule.id,
      }).then(res => {
        if (!res.hasErrors()) {
          this.$appNotice.success(this.$t('common.msg create ok'))
          this.redirectBack()
        } else {
          this.$appNotice.error()
        }
      })
    },
    saveBooking() {
      this.$refs.form_create_entry.validate().then((result) => {
        if (!result) {
          this.scrollToValidateError();
          return false;
        }
        if (this.entry.use_kidchair && this.entry.kidchair_num > 0) {
          if (!this.kidchair_validated) {
            this.$refs.kidchair_notice.show();
            return false;
          }
        } else {
          this.entry.kidchair_num = null;
        }
        if (this.entry.date_ranks && Object.keys(this.entry.date_ranks).length > 0) {
          if (!this.validateDateRank()) {
            this.$refs.modal_date_ranks.show();
            return false;
          }
        }
        var toIds = this.touristOptions.map(to => to.id)
        this.entry.tourist_options = this.entry.tourist_options.filter(id => toIds.includes(id))
        var entryComputed = _.cloneDeep(this.entryComputed);
        var commaPriceFields = ['c_num_total', 'c_price_1', 'c_price_2', 'c_price_3', 'c_price_4', 'c_price_5',
          'c_price_driver', 'c_price_total', 'total_price_taxi', 'total_price_hotel',
          'total_price_taxi_with_options', 'total_price_final'];
        _.forEach(commaPriceFields, field => {
          if (entryComputed[field] && (typeof entryComputed[field] === 'string')) {
            entryComputed[field] = entryComputed[field].replace(/,/g, "");
          }
        })
        this.$request.post(this.$consts.API.CS.BOOKING_SAVE, {
          ...this.entry,
          ...entryComputed,
          hotel_fee_rule_id: this.hotelFeeRule.id,
          taxi_fee_rule_id: this.taxiFeeRule.id,
        }).then(res => {
          if (!res.hasErrors()) {
            this.$appNotice.success(this.$t('common.msg create ok'))
            this.redirectBack()
          } else {
            if (res.data.error_code === 'KIDCHAIR_NOT_AVAILABLE') {
              this.checkAvailableKidchair(0, false)
            } else if (res.data.error_code === 'TAXI_TYPE_NOT_AVAILABLE') {
              this.getTaxiTypes(0, false, () => {
                this.$refs.form_create_entry.validate().then((result) => {
                  this.scrollToValidateError();
                  return false;
                });
              })
            } else {
              this.$appNotice.error()
            }
          }
        })
      })
    },
    scrollToValidateError(timeout = 0) {
      this.$nextTick(() => {
        setTimeout(() => {
          var elToScroll = null;
          let elementsInErrors = document.getElementsByClassName('error');
          if (elementsInErrors && elementsInErrors.length > 0) {
            elToScroll = elementsInErrors[0];
          }
          if (elToScroll) {
            $('html, body').animate({
              scrollTop: $(elToScroll).offset().top - 200
            }, 500)
          }
        }, timeout);
      });
    },
    redirectBack() {
      if (this.$store.state.setting.prevRouter.name === this.$consts.ROUTES.CS.BOOKING_LIST) {
        this.$router.go(-1);
      } else {
        this.$router.pushByName(this.$consts.ROUTES.CS.BOOKING_LIST);
      }
    }
  },
}
</script>

<style scoped lang="scss">
.group-input-label {
  width: 100px;
  background: rgb(247, 251, 255);
}

.break-left {
  border-left: 1px solid #015CAC;
}

.break-right {
  border-right: 1px solid #015CAC;
}

.break-bottom {
  border-bottom: 1px solid #015CAC;
}


.group-input-value {
  width: calc(100% - 115px);
  margin-left: 15px;
}

.day-count {
  height: 100px;
  background: #F7FBFF;
  border: 1px solid #cdcdcd;
  border-radius: 3px
}

.container-fluid {
  padding: 0;
}

.radio {
  margin-bottom: -10px;

  & label {
    margin: 0;

    &:before {
      width: 30px;
      height: 30px;
    }
  }
}

.radio input[type=radio]:checked + label:before {
  border-width: 10px;
}

.has-error td {
  background: #ffc9c9 !important;
}
</style>
